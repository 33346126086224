import {
  ACTION_CLEAN_DIARY,
  ACTION_GENERATE_MY_ID,
  ACTION_SAVE_LAST_NAME,
  ACTION_SET_DIARY,
  ACTION_SET_SHOW_ROLES,
} from './actions'
import { combineReducers } from 'redux'
import { nanoid } from 'nanoid'

const me = (state = {}, { type, payload }) => {
  switch (type) {
    case ACTION_GENERATE_MY_ID:
      return {
        ...state,
        id: payload.id,
      }
    case ACTION_SAVE_LAST_NAME:
      return {
        ...state,
        lastName: payload.lastName,
      }
    default:
      return state
  }
}

const initialCurrent = {
  objectPlayerId: [],
  subjectPlayerId: [],
  action: null,
}

const initialDiarySet = {
  current: { ...initialCurrent },
  history: [],
  showRoles: false,
}

const diary = (state = { ...initialDiarySet }, { type, payload }) => {
  switch (type) {
    case ACTION_SET_SHOW_ROLES:
      const { showRoles } = payload

      return {
        ...state,
        showRoles,
      }
    case ACTION_CLEAN_DIARY:
      const { entryId } = payload

      if (entryId) {
        const history = state.history.filter((entry) => entryId !== entry.id)
        return {
          ...state,
          history,
        }
      }

      return { ...initialDiarySet }

    case ACTION_SET_DIARY:
      const { objectPlayerId, subjectPlayerId, action } = payload
      const newDiary = {
        ...state.current,
      }

      if (objectPlayerId) {
        if (newDiary.objectPlayerId.includes(objectPlayerId)) {
          newDiary.objectPlayerId = [
            ...newDiary.objectPlayerId.filter((i) => i !== objectPlayerId),
          ]
        } else {
          newDiary.objectPlayerId = [...newDiary.objectPlayerId, objectPlayerId]
        }
      }

      if (subjectPlayerId) {
        if (newDiary.subjectPlayerId.includes(subjectPlayerId)) {
          newDiary.subjectPlayerId = [
            ...newDiary.subjectPlayerId.filter((i) => i !== subjectPlayerId),
          ]
        } else {
          newDiary.subjectPlayerId = [
            ...newDiary.subjectPlayerId,
            subjectPlayerId,
          ]
        }
      }

      if (action) {
        newDiary.action = action
      }

      if (
        newDiary.objectPlayerId.length > 0 &&
        newDiary.subjectPlayerId.length > 0 &&
        newDiary.action
      ) {
        const newHistoryEntries = []
        newDiary.objectPlayerId.forEach((opId) => {
          newDiary.subjectPlayerId.forEach((spId) => {
            const id = nanoid(5)
            newHistoryEntries.push({
              id,
              objectPlayerId: opId,
              subjectPlayerId: spId,
              action: newDiary.action,
            })
          })
        })

        return {
          ...state,
          current: { ...initialCurrent },
          history: [...state.history, ...newHistoryEntries],
        }
      }

      return {
        ...state,
        current: newDiary,
      }
    default:
      return state
  }
}

export const reducers = combineReducers({
  me,
  diary,
})
