import { GIVE_ROLES } from '../graphql/queries'
import arrayShuffle from 'array-shuffle'
import { client } from '../apollo/client'
import { generatePlayerId } from '../utils'

export const ACTION_GENERATE_MY_ID = 'ME/GENERATE_ID'
export const ACTION_SAVE_LAST_NAME = 'ME/SAVE_NAME'
export const ACTION_SET_DIARY = 'DIARY/SET_DIARY'
export const ACTION_CLEAN_DIARY = 'DIARY/CLEAN_DIARY'
export const ACTION_SET_SHOW_ROLES = 'DIARY/SET_SHOW_ROLES'

export const actionSaveLastName = ({ lastName }) => ({
  type: ACTION_SAVE_LAST_NAME,
  payload: {
    lastName,
  },
})

export const actionGenerateMyId = () => ({
  type: ACTION_GENERATE_MY_ID,
  payload: {
    id: generatePlayerId(),
  },
})

export const giveRoles = ({ gameId, players, roles }) => {
  const newPlayers = []
  const rolesAr = []
  Object.keys(roles).forEach((role) => {
    let count = roles[role]
    while (count > 0) {
      rolesAr.push(role)
      count--
    }
  })

  rolesAr.length = players.length
  const shuffled = arrayShuffle(rolesAr)
  shuffled.forEach((role, ind) => {
    const { name, id } = players[ind]

    newPlayers.push({
      name,
      id,
      role: role || null,
    })
  })

  client.mutate({
    mutation: GIVE_ROLES,
    variables: {
      gameId,
      players: newPlayers,
    },
  })

  return {
    type: 'DO_NOTHING',
  }
}

export const actionSetDiary = (payload) => ({
  type: ACTION_SET_DIARY,
  payload,
})

export const actionCleanDiary = (payload) => ({
  type: ACTION_CLEAN_DIARY,
  payload: {
    entryId: payload ? payload.entryId : undefined,
  },
})

export const actionSetShowRoles = (showRoles) => ({
  type: ACTION_SET_SHOW_ROLES,
  payload: {
    showRoles,
  },
})
