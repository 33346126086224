import { customAlphabet, nanoid } from 'nanoid'
import BEMHelper from 'react-bem-helper'
const nanoidNumber = customAlphabet('1234567890', 12)

export const generateId = () => {
  const id = nanoidNumber()
  return `${id.slice(0, 4)}-${id.slice(4, 8)}-${id.slice(8)}`
}

export const generatePlayerId = () => {
  return nanoid()
}

export const formatNumber = (n) => {
  if (n < 10) {
    return `0${n}`
  }

  return `${n}`
}

export const getClasses = (name) =>
  new BEMHelper({
    name,
  })
