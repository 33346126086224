// import { InMemoryCache } from 'apollo-cache-inmemory'
// import AWSAppSyncClient from 'aws-appsync'
// import awsConfig from '../config/aws.json'
// import 'isomorphic-fetch'
//
// export const client = new AWSAppSyncClient({
//   url: awsConfig.GraphQlApiUrl,
//   region: awsConfig.region,
//   auth: {
//     type: 'API_KEY',
//     apiKey: awsConfig.GraphQlApiKeyDefault,
//   },
//   cache: new InMemoryCache(),
// })

import awsConfig from '../config/aws.json'
import { ApolloLink } from 'apollo-link'
import ApolloClient from 'apollo-client'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { createHttpLink } from 'apollo-link-http'
import { createAuthLink } from 'aws-appsync-auth-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import 'isomorphic-fetch'

const url = awsConfig.GraphQlApiUrl
const region = awsConfig.region
const auth = {
  type: 'API_KEY',
  apiKey: awsConfig.GraphQlApiKeyDefault,
}

const httpLink = createHttpLink({ uri: url })

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink(url, httpLink),
])

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions,
})
