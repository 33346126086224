import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from './client'
import { Provider } from 'react-redux'
import { persistor, store } from '../store'
import { PersistGate } from 'redux-persist/integration/react'

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </PersistGate>
  </Provider>
)
