import { createStore } from 'redux'
import { reducers } from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'mafia.wal.de',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export function configureStore(initialState = {}) {
  return createStore(
    persistedReducer,
    typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
  )
}

export const store = configureStore()
export const persistor = persistStore(store)
