import gql from 'graphql-tag'

export const CREATE_GAME = gql`
  mutation CreateGame($id: String!) {
    createGame(input: { id: $id }) {
      id
      players {
        name
        role
        id
      }
      state
    }
  }
`

export const DELETE_GAME = gql`
  mutation DeleteGame($id: String!) {
    deleteGame(input: { id: $id }) {
      id
    }
  }
`

export const ADD_PLAYER = gql`
  mutation AddPlayer(
    $gameId: String!
    $playerName: String!
    $playerId: String!
  ) {
    addPlayer(
      input: { gameId: $gameId, playerName: $playerName, playerId: $playerId }
    ) {
      id
      players {
        name
        role
        id
      }
      state
    }
  }
`

export const SET_TIMER = gql`
  mutation SetTimer($gameId: String!, $duration: Int) {
    setTimer(input: { gameId: $gameId, duration: $duration }) {
      id
      timer {
        duration
        start
      }
    }
  }
`

export const GIVE_ROLES = gql`
  mutation GiveRoles($gameId: String!, $players: [PlayerInput!]!) {
    giveRoles(input: { gameId: $gameId, players: $players }) {
      id
      players {
        name
        role
        id
      }
      state
    }
  }
`

export const ON_ADD_PLAYER = gql`
  subscription OnAddPlayer($id: String!) {
    onAddPlayer(id: $id) {
      id
      players {
        name
        role
      }
      state
    }
  }
`

export const ON_SET_TIMER = gql`
  subscription OnSetTimer($id: String!) {
    onSetTimer(id: $id) {
      id
      timer {
        duration
        start
      }
    }
  }
`

export const GET_GAME = gql`
  query GetGame($id: String!) {
    getGame(input: { id: $id }) {
      id
      players {
        name
        role
        id
      }
      state
    }
  }
`

export const GET_TIMER = gql`
  query GetTimer($id: String!) {
    getTimer(input: { id: $id }) {
      id
      timer {
        duration
        start
      }
    }
  }
`
